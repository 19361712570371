<template>
    <div id="settings" class="container">
        <div class="row">
            <SubjectSettings class="mt-4"></SubjectSettings>
            <TypesSettings @reload="reload" class="mt-4"></TypesSettings>
            <GradeTresholdSettings class="mt-4"></GradeTresholdSettings>
            <InactiveTypesSettings @reload="reload" class="mt-4"></InactiveTypesSettings>
        </div>
    </div>
</template>

<script>
import SubjectSettings from '../components/SubjectSettings';
import TypesSettings from '../components/TypesSettings';
import InactiveTypesSettings from '../components/InactiveTypesSettings';
import GradeTresholdSettings from '../components/GradeTresholdSettings';

export default {
    name: 'Settings',
    components: {
        SubjectSettings,
        TypesSettings,
        InactiveTypesSettings,
        GradeTresholdSettings
    },
    methods: {
        reload() {
            console.log('reload at parent');
        }
    }
};
</script>

<style></style>
