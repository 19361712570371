<template>
    <canvas :id="'grade-canvas' + this._uid" :width="width" height="70"></canvas>
</template>

<script>
export default {
    name: 'GradeChart',
    props: ['width', 'grade_tresholds', 'grade_percent'],
    mounted() {
        var canvasElement = document.querySelector('#grade-canvas' + this._uid);
        var context = canvasElement.getContext('2d');

        var grd = context.createLinearGradient(0, 25, this.width, 25);
        grd.addColorStop(0, '#7FE817');
        grd.addColorStop(0.6, 'orange');
        grd.addColorStop(1, '#F75D59');

        context.fillStyle = grd;
        context.fillRect(0, 30, this.width, 10);

        let yeets = [0];

        this.grade_tresholds.forEach((treshold) => {
            yeets.push(this.width - (this.width / 100) * treshold);
        });
        yeets.push(this.width);
        console.log(yeets);

        yeets.forEach((el) => {
            context.moveTo(el, 30);
            context.lineTo(el, 40);
            context.stroke();
        });

        context.fillStyle = 'black';

        yeets.shift();
        yeets.pop();
        yeets.forEach((el, index) => {
            context.fillText(this.grade_tresholds[index] + '%', el - 10, 28);
        });

        let grade_percent_inverted = 100 + 0 - this.grade_percent;

        let grade_x = (this.width / 100) * grade_percent_inverted;
        console.log('Drawing grade at: ' + grade_x + 'px');

        context.beginPath();
        context.strokeStyle = '#000';
        context.moveTo(grade_x, 40);
        context.lineTo(grade_x - 7, 52);
        context.lineTo(grade_x + 7, 52);
        context.closePath();
        context.fill();

        context.font = '15px Verdana';
        context.textAlign = 'center';
        if (grade_x < 20) {
            context.fillText(this.grade_percent + '%', 20, 67);
        } else if (grade_x > this.width - 20) {
            context.fillText(this.grade_percent + '%', this.width - 20, 67);
        } else {
            context.fillText(this.grade_percent + '%', grade_x, 67);
        }
    }
};
</script>

<style></style>
