<template>
    <div class="col-md-6 col-12">
        <h3>Moje typy známek</h3>
        <b-table id="type-table" :items="types" :fields="fields" head-variant="dark" small>
            <template #cell(color)="row">
                <div class="color-box mx-auto" :style="'background-color: ' + row.item.color"></div>
            </template>
            <template #cell(modify)="row">
                <b-dropdown text="úpravy" variant="warning" size="sm">
                    <b-dropdown-item-button
                        size="sm"
                        variant="danger"
                        @click="deactivateType(row.item.id)"
                        >deaktivovat</b-dropdown-item-button
                    >
                    <b-dropdown-item-button size="sm" variant="info" @click="editModal(row.item)"
                        >editovat</b-dropdown-item-button
                    >
                </b-dropdown>
            </template>
        </b-table>
        <h4><u>Nový typ známky</u></h4>
        <b-form inline ref="typeform" @submit.prevent="createType">
            <b-form-group label="Název" label-for="type-name">
                <b-form-input
                    size="sm"
                    v-model="newType.name"
                    id="type-name"
                    type="text"
                    class="m-2"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group label="Maxiumum bodů" label-for="type-max">
                <b-form-input
                    size="sm"
                    v-model="newType.maximum"
                    id="type-max"
                    type="number"
                    class="m-2"
                    min="1"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group>
                <ColorPicker
                    class="m-2"
                    :value="newType.color"
                    :palette="palette"
                    @input="updateColor"
                ></ColorPicker>
            </b-form-group>
            <b-button type="submit" size="sm" class="m-1" variant="success">Přidat</b-button>
        </b-form>
        <b-modal
            id="type-edit"
            ok-only
            ok-title="Uložit"
            title="Editace typu známky"
            @ok="saveEdit"
            @hide="$refs.typeform.reset()"
        >
            <b-form class="row">
                <b-form-group label="Název" label-for="txt-name" class="col-6">
                    <b-form-input type="text" id="txt-name" v-model="newType.name"></b-form-input>
                </b-form-group>
                <b-form-group label="Maximální body" label-for="num-max" class="col-6">
                    <b-form-input
                        min="1"
                        type="number"
                        id="num-max"
                        v-model="newType.maximum"
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Barva podkladu" label-for="sel-col">
                    <ColorPicker
                        id="sel-col"
                        class="m-2"
                        :value="newType.color"
                        :palette="palette"
                        @input="updateColor"
                    ></ColorPicker>
                </b-form-group>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import { Compact } from 'vue-color';
export default {
    name: 'TypesSubject',
    components: {
        ColorPicker: Compact
    },
    data() {
        return {
            types: [],
            fields: [
                {
                    key: 'name',
                    label: 'Jméno'
                },
                {
                    key: 'maximum',
                    label: 'Maximum bodů'
                },
                {
                    key: 'color',
                    label: 'Barva podkladu'
                },
                {
                    key: 'modify',
                    label: 'Úpravy'
                }
            ],
            currentId: undefined,
            newType: {
                name: undefined,
                maximum: undefined,
                color: '#000000'
            },
            palette: [
                '#69d2df',
                '#f27048',
                '#69e7a6',
                '#c87aec',
                '#86db5a',
                '#eb70c3',
                '#52c464',
                '#898fee',
                '#dbe45f',
                '#4fa2e8',
                '#deb439',
                '#90a7e2',
                '#a6b127',
                '#ca96d9',
                '#7bb149',
                '#e77796',
                '#66e5d1',
                '#e88530',
                '#63b3d6',
                '#d89535',
                '#9fa3c1',
                '#c5e892',
                '#ef7470',
                '#4db28e',
                '#dea7c9',
                '#afb359',
                '#cad2e8',
                '#d6b165',
                '#84aba4',
                '#dd9672',
                '#bae5c9',
                '#c7a19b',
                '#89b37b',
                '#e5d8b0',
                '#aca677'
            ]
        };
    },
    methods: {
        async createType() {
            try {
                const post = await this.axios.post(`/api/types`, this.newType);
                if (post.status == 204) {
                    this.reload();
                    this.$bvModal.hide('type-creation');
                    this.$refs.typeform.reset();
                }
            } catch (err) {
                alert('error, viz console');
                console.error(err);
            }
        },
        async deactivateType(id) {
            if (!confirm('Určitě?')) {
                return;
            }

            try {
                const del = await this.axios.delete(`/api/type/${id}`);
                if (del.status == 204) {
                    this.reload();
                    this.$parent.$emit('reload');
                }
            } catch (err) {
                if (err.response.status == 400) {
                    alert('Tento typ známky je stále používaný u některých studentů.');
                } else {
                    alert('error, viz console');
                    console.error(err);
                }
            }
        },
        async reload() {
            try {
                const subjs = await this.axios.get('/api/types/active');
                if (subjs.status == 200) {
                    this.types = subjs.data;
                }
            } catch (err) {
                alert('error, viz console');
                console.error(err);
            }
        },
        updateColor(color) {
            this.newType.color = color.hex;
        },
        submit() {
            const kek = this.$refs.typeform;
            console.log(kek);
            kek.submit();
        },
        editModal(type) {
            console.log(type);
            this.currentId = type.id;

            this.newType = {
                name: type.name,
                maximum: type.maximum,
                color: type.color
            };

            this.$bvModal.show('type-edit');
        },
        async saveEdit() {
            const put = await this.axios.put(`/api/type/${this.currentId}`, this.newType);
            if (put.status == 204) {
                this.$bvModal.hide('type-edit');
                this.reload();
                this.$refs.typeform.reset();
                this.newType.color = '#000000';
                this.$parent.$emit('reload');
            }
        }
    },
    mounted() {
        this.reload();

        this.$parent.$on('reload', this.reload);
    }
};
</script>

<style>
.color-box {
    width: 25px;
    height: 25px;
    border-radius: 50%;
}
#type-table {
    overflow-y: scroll;
    max-height: 400px;
    display: block;
}
</style>
