<template>
    <div id="program" class="container">
        <div class="row my-3">
            <b-button v-b-modal.add-program variant="success">Přidat náplň týdne</b-button>
        </div>
        <div class="row">
            <b-table
                striped
                :fields="table_fields"
                :items="programs"
                :show-empty="true"
                empty-text="Žádné záznamy..."
            >
                <template #cell(id)="data">
                    <!-- <b-button class="mr-1" variant="warning" size="sm">Edit </b-button> -->
                    <b-button @click="delete_program(data.item.id)" variant="danger" size="sm"
                        >Smazat</b-button
                    >
                </template>
            </b-table>
        </div>
        <b-modal
            @ok="add_program"
            :ok-disabled="form_state"
            id="add-program"
            title="Přidat náplň dne pro mé žáky"
        >
            <b-form class="row">
                <b-form-group label="Ročník" class="col-4" label-for="np-grade">
                    <b-form-select
                        id="np-grade"
                        :options="grade_options"
                        v-model="new_program.grade"
                        required
                    ></b-form-select>
                </b-form-group>
                <b-form-group
                    label="Datum"
                    label-for="np-date"
                    class="col-6"
                    :state="date_state"
                    invalid-feedback="Vyberte týden"
                >
                <b-form-select v-model="new_program.date" :options="options" size="sm" class="mt-3"></b-form-select>
                    <!-- <DatePicker
                        :monday-first="true"
                        :disabled-dates="{
                            days: [0, 1, 2, 3, 4, 5, 6]
                        }"
                        format="dd.MM.yyyy"
                        v-model="new_program.date"
                        :bootstrap-styling="true"
                        id="np-date"
                    ></DatePicker> -->
                </b-form-group>
                <b-form-group
                    class="col-12"
                    label="Obsah"
                    label-for="np-text"
                    :state="text_state"
                    invalid-feedback="Zadejte alespoň 3 znaky"
                >
                    <b-form-textarea id="np-text" v-model="new_program.text"></b-form-textarea>
                </b-form-group>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
// import DatePicker from 'vuejs-datepicker';

export default {
    name: 'Program',
    components: {
        // DatePicker
    },
    data() {
        return {
            programs: [],
            grade_options: [1, 2, 3, 4, 5],
            new_program: {
                grade: 1,
                date: undefined,
                text: '',
                user_id: 0
            },
            table_fields: [
                { key: 'grade', label: 'Ročník' },
                { key: 'date', label: 'Datum' },
                { key: 'text', label: 'Obsah' },
                { key: 'id', label: 'Změny' }
            ],
            selected: undefined,
            options: this.weeks()
        };
    },
    methods: {
        weeks() {
            
            let out = [];

            for (let i = 0; i < 15; i++) {
                let currentWeek = (this.$moment().week() + i - 1) % 52 + 1;
                let startOfWeek = this.$moment().startOf('week').add(7 * i, 'd');
                let endOfWeek = this.$moment().endOf('week').add(7 * i, 'd');

                console.log(startOfWeek);
                out.push({
                    text: `Týden č. ${currentWeek} (${startOfWeek.format("DD.MM.YYYY")} - ${endOfWeek.format("DD.MM.YYYY")})`,
                    value: startOfWeek.format("YYYY-MM-DD")
                })
                
            }

            return out;
        },
        add_program() {
            this.new_program.date = this.$moment(this.new_program.date).format('YYYY-MM-DD');
            //TODO: DODELAT HANDLING EXCEPTIONS

            this.new_program.user_id = this.$store.getters.teacherId

            this.axios
                .post('/api/program', this.new_program)
                .then(() => {
                    this.refresh();
                })
                .catch((err) => {
                    alert('Error, viz console.');
                    console.error(err);
                });
        },
        delete_program(program_id) {
            this.axios
                .delete(`/api/program/${program_id}`)
                .then((resp) => {
                    this.refresh();
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        async refresh() {
            const programs = await this.axios.get('/api/programs');
            this.programs = programs.data;

            this.programs.forEach((el) => {
                let currentWeek = (this.$moment(el.date).week());
                let startOfWeek = this.$moment(el.date).startOf('week');
                let endOfWeek = this.$moment(el.date).endOf('week');

                el.date = `Týden č. ${currentWeek} (${startOfWeek.format("DD.MM.YYYY")} - ${endOfWeek.format("DD.MM.YYYY")})`;
            });


            this.new_program = {
                grade: 1,
                date: Date.now(),
                text: '',
                user_id: 0
            };
        }
    },
    computed: {
        date_state() {
            return !!this.new_program.date;
        },
        form_state() {
            return !this.new_program.date || !this.new_program.text;
        },
        text_state() {
            return this.new_program.text.length >= 3;
        }
    },
    mounted() {
        this.refresh();
    }
};
</script>

<style></style>
