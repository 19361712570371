<template>
    <div id="evaluations" class="container">
        <div class="row">
            <div class="col-10 col-md-4 col-sm-6 mt-3 mx-auto">
                Datum:
                <DatePicker
                    :monday-first="true"
                    :disabled-dates="disabledDates"
                    format="dd.MM.yyyy"
                    v-model="selectedDate"
                    :bootstrap-styling="true"
                    @selected="getEvals"
                ></DatePicker>
            </div>
        </div>
        <div class="row">
            <div v-if="loaded" class="col-10 mx-auto">
                <div class="mx-2 mt-2 p-1" v-for="student in studentEvals" :key="student.id">
                    <p class="student-name h4 underline">
                        {{ student.name }}
                        <small>{{ student.year }}.třída</small>
                    </p>
                    <b-form-textarea
                        class="w-100"
                        v-model="student.text"
                        placeholder="Bez hodnocení..."
                    ></b-form-textarea>
                </div>
            </div>
        </div>
        <span id="sticky-situation">
            <p
                :class="[statusSuccess ? 'text-success' : 'text-danger']"
                class="mx-auto mt-3"
                id="status-text"
            >
                <strong>{{ statusText }}</strong>
            </p>
            <button @click="update" type="button" class="mx-auto btn btn-success">Uložit</button>
        </span>
    </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';

export default {
    name: 'Evaluations',
    components: {
        DatePicker
    },
    data() {
        return {
            selectedDate: undefined,
            disabledDates: {
                days: [0, 6]
            },
            studentEvals: [],
            loaded: false,
            statusText: undefined,
            statusSuccess: undefined
        };
    },
    methods: {
        getEvals(date) {
            this.statusText = '';
            this.actualDate = this.$moment(date);
            this.axios
                .get(`/api/evals/${this.actualDate.format('YYYY-MM-DD')}`)
                .then((response) => {
                    this.studentEvals = this.sortNames(response.data);
                    this.oldStudentEvals = JSON.parse(JSON.stringify(response.data));
                });
        },
        sortNames(data) {
            return data.sort((a, b) => {
                let name1 = a.name.trim().split(' ')[0].toLowerCase();
                let name2 = b.name.trim().split(' ')[0].toLowerCase();
                
                return name1.localeCompare(name2, 'cs-CZ');
            });
        },
        update() {
            let kek = Array();
            this.studentEvals.forEach((el, i) => {
                el.oldText = this.oldStudentEvals[i].text;
                kek.push(el);
            });

            this.axios
                .put(`/api/evals/${this.actualDate.format('YYYY-MM-DD')}`, kek)
                .then((response) => {
                    this.getEvals(this.selectedDate);
                    if (response.status == 204) {
                        this.statusSuccess = true;
                        this.statusText = 'Uloženo';
                        setTimeout(() => {
                            this.statusText = '';
                        }, 2000);
                    }
                })
                .catch((err) => {
                    this.statusSuccess = false;
                    this.statusText = 'Error: ' + err.message;
                });
        }
    },
    mounted() {
        let now = Date.now();
        this.getEvals(now);
        this.selectedDate = now;
        this.loaded = true;
    }
};
</script>

<style></style>
