<template>
    <div class="col-md-6 col-12">
        <h3>Moje předměty</h3>
        <b-table :items="subjects" :fields="fields" head-variant="dark" small>
            <template #cell(edit)="row">
                <b-button size="sm" variant="info" @click="editModal(row.item)">editovat</b-button>
            </template>
            <template #cell(delete)="row">
                <b-button size="sm" variant="danger" @click="removeSubject(row.item.id)"
                    >smazat</b-button
                >
            </template>
        </b-table>
        <h5><u>Nový předmět</u></h5>
        <b-form inline @submit.prevent="createSubject" ref="subjform">
            <b-form-group label="Zkratka" label-for="txt-name">
                <b-form-input
                    size="sm"
                    v-model="newSubject.name"
                    id="txt-name"
                    type="text"
                    class="m-2"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group label="Název" label-for="txt-long-name">
                <b-form-input
                    size="sm"
                    v-model="newSubject.long_name"
                    id="txt-long-name"
                    type="text"
                    class="m-2"
                    required
                ></b-form-input>
            </b-form-group>
            <b-form-group label="Ročník" label-for="sel-year">
                <b-form-select
                    size="sm"
                    class="m-2"
                    v-model="newSubject.year"
                    :options="yearOptions"
                    required
                ></b-form-select>
            </b-form-group>
            <b-button type="submit" size="sm" variant="success" class="m-2">Přidat</b-button>
        </b-form>
        <b-modal
            id="subj-edit"
            ok-only
            ok-title="Uložit"
            title="Editace předmětu"
            @ok="saveEdit"
            @hide="$refs.subjform.reset()"
        >
            <b-form class="row">
                <b-form-group label="Zkratka" label-for="txt-name" class="col-6">
                    <b-form-input
                        type="text"
                        id="txt-name"
                        v-model="newSubject.name"
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Název" label-for="txt-longname" class="col-6">
                    <b-form-input
                        type="text"
                        id="txt-longname"
                        v-model="newSubject.long_name"
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Ročník" label-for="sel-year" class="col-6">
                    <b-form-select
                        size="sm"
                        v-model="newSubject.year"
                        :options="yearOptions"
                        required
                    ></b-form-select>
                </b-form-group>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'SubjectSettings',
    data() {
        return {
            subjects: [],
            yearOptions: [1, 2, 3, 4, 5],
            fields: [
                {
                    key: 'name',
                    label: 'Zkratka'
                },
                {
                    key: 'long_name',
                    label: 'Název'
                },
                {
                    key: 'year',
                    label: 'Ročník',
                    formatter: (value) => {
                        return value + '.';
                    }
                },
                {
                    key: 'edit',
                    label: 'Edit'
                },
                {
                    key: 'delete',
                    label: 'Smazat'
                }
            ],
            currentId: undefined,
            newSubject: {
                name: undefined,
                long_name: undefined
            }
        };
    },
    methods: {
        async createSubject(event) {
            try {
                const post = await this.axios.post(`/api/subjects`, this.newSubject);
                if (post.status == 204) {
                    this.reload();
                    event.target.reset();
                }
            } catch (err) {
                alert('error, viz console');
                console.error(err);
            }
        },
        async removeSubject(id) {
            if (!confirm('Určitě?')) {
                return;
            }

            try {
                const del = await this.axios.delete(`/api/subject/${id}`);
                if (del.status == 204) {
                    this.reload();
                }
            } catch (err) {
                if (err.response.status == 400) {
                    alert(
                        'Tento předmět je stále používaný u některých studentů. Nelze jej tudíž smazat.'
                    );
                } else {
                    alert('error, viz console');
                    console.error(err);
                }
            }
        },
        async reload() {
            try {
                const subjs = await this.axios.get('/api/mysubjects');
                if (subjs.status == 200) {
                    this.subjects = subjs.data;
                }
            } catch (err) {
                alert('error, viz console');
                console.error(err);
            }
        },
        editModal(subj) {
            this.currentId = subj.id;

            this.newSubject = {
                name: subj.name,
                long_name: subj.long_name,
                year: subj.year
            };

            this.$bvModal.show('subj-edit');
        },
        async saveEdit() {
            try {
                const put = await this.axios.put(`/api/subject/${this.currentId}`, this.newSubject);
                if (put.status == 204) {
                    this.$bvModal.hide('subj-edit');
                    this.reload();
                    this.$refs.subjform.reset();
                }
            } catch (err) {
                alert('error, viz console');
                console.error(err);
            }
        }
    },
    mounted() {
        this.reload();
    }
};
</script>

<style></style>
