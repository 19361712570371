<template>
    <div id="app" class="mb-5">
        <b-navbar toggleable="md" type="dark" variant="dark" v-if="$store.state.teacher.loggedIn">
            <b-navbar-brand href="#">
                <img id="hdvi-logo" src="../public/grade128.png" alt="HDVi Logo" />
                <span id="hdvi-text">HDVi Čeperka</span>
            </b-navbar-brand>

            <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

            <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                    <router-link to="/hodnoceni" class="nav-link" active-class="active"
                        >Hodnocení</router-link
                    >
                    <router-link to="/znamky" class="nav-link" active-class="active"
                        >Známky</router-link
                    >
                    <router-link to="/program" class="nav-link" active-class="active"
                        >Náplň týdne</router-link
                    >
                    <router-link to="/zaci" class="nav-link" active-class="active"
                        >Seznam žáků</router-link
                    >
                    <router-link to="/souhrnhodnoceni" class="nav-link" active-class="active"
                        >Souhrn hodnocení</router-link
                    >
                    <router-link to="/nastaveni" class="nav-link" active-class="active"
                        >Nastavení</router-link
                    >
                    <router-link
                        v-if="teacher.role == 0"
                        to="/management"
                        class="nav-link"
                        active-class="active"
                        >Management</router-link
                    >
                </b-navbar-nav>

                <b-navbar-nav class="ml-auto">
                    <b-button @click="logout" type="button" variant="danger" size="sm">
                        Odhlásit učitele {{ teacher.name }}
                    </b-button>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
        <router-view />
        <footer class="mt-5" id="app-footer">Cobyloveskole.cz {{ version }} © O.Pithart</footer>
    </div>
</template>

<script>
import { version } from '../package.json';

export default {
    name: 'App',
    data() {
        return {
            version
        };
    },
    methods: {
        logout() {
            document.cookie = `token=; path=/; domain=${document.location.hostname}; expires=Thu, 01 Jan 1970 00:00:00 UTC; sameSite=Strict`;
            this.$store.commit('logOut');
            this.axios.delete('/api/session');
        }
    },
    computed: {
        teacher() {
            return this.$store.state.teacher;
        }
    }
};
</script>

<style>
#app-footer {
    position: fixed;
    left: 0;
    bottom: 0;
    width: 100%;
    /* background-color: white; */
    color: gray;
    text-align: center;
}
#hdvi-logo {
    height: 35px;
    margin-right: 7px;
}
#hdvi-text {
    vertical-align: middle;
}
</style>
