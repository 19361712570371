import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import VueAxios from 'vue-axios';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;

Vue.use(VueAxios, axios);
Vue.use(BootstrapVue, IconsPlugin);

const moment = require('moment');
require('moment/locale/cs');

Vue.use(require('vue-moment'), {
    moment
});

router.beforeEach(async (to, from, next) => {
    if (!to.matched.some((record) => record.meta.requiresAuth) || store.state.teacher.loggedIn) {
        next();
        return;
    }

    try {
        const response = await axios.get('/api/session');
        if (response.status == 200) {
            store.commit('logIn', response.data);
            next();
        } else {
            next({
                path: '/login'
            });
        }
    } catch (error) {
        next({
            path: '/login'
        });
    }
});

new Vue({
    router,
    store,
    axios,
    render: (h) => h(App)
}).$mount('#app');
