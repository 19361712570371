<template>
    <div id="grade-settings" class="col-md-6 col-12">
        <h3>Hranice známek</h3>
        <b-table
            selectable
            selected-variant=""
            :fields="fields"
            :items="gradeTresholds"
            head-variant="dark"
            small
        >
            <template #cell(one_two)="row">
                <b-form-input
                    v-if="row.rowSelected"
                    v-model="row.item.one_two"
                    type="number"
                    size="sm"
                    class="text-edit"
                    min="1"
                    max="99"
                ></b-form-input>
                <span v-else>{{ row.item.one_two }}%</span>
            </template>
            <template #cell(two_three)="row">
                <b-form-input
                    v-if="row.rowSelected"
                    v-model.number="row.item.two_three"
                    type="number"
                    size="sm"
                    class="text-edit"
                    min="1"
                    max="99"
                ></b-form-input>
                <span v-else>{{ row.item.two_three }}%</span>
            </template>
            <template #cell(three_four)="row">
                <b-form-input
                    v-if="row.rowSelected"
                    v-model.number="row.item.three_four"
                    type="number"
                    size="sm"
                    class="text-edit"
                    min="1"
                    max="99"
                ></b-form-input>
                <span v-else>{{ row.item.three_four }}%</span>
            </template>
            <template #cell(four_five)="row">
                <b-form-input
                    v-if="row.rowSelected"
                    v-model.number="row.item.four_five"
                    type="number"
                    size="sm"
                    class="text-edit"
                    min="1"
                    max="99"
                ></b-form-input>
                <span v-else>{{ row.item.four_five }}%</span>
            </template>
            <template #cell(edit-icon)="row">
                <b-button variant="info" size="sm" @click="editRow(row)">editovat</b-button>
            </template>
        </b-table>
    </div>
</template>

<script>
export default {
    name: 'GradeTresholdSettings',
    data() {
        return {
            gradeTresholds: [],
            valid: true,
            fields: [
                {
                    key: 'year',
                    label: 'Ročník'
                },
                {
                    key: 'one_two',
                    label: '1 - 2'
                },
                {
                    key: 'two_three',
                    label: '2 - 3'
                },
                {
                    key: 'three_four',
                    label: '3 - 4'
                },
                {
                    key: 'four_five',
                    label: '4 - 5'
                },
                {
                    key: 'edit-icon',
                    label: 'Upravit'
                }
            ]
        };
    },
    methods: {
        async editRow(row) {
            if (row.rowSelected) {
                let yeey = await this.updateTresholds(row.item);
                if (yeey) {
                    row.unselectRow();
                }
            } else {
                row.selectRow();
            }
        },
        async updateTresholds(updatedTreshold) {
            let valid = true;
            for (const prop in updatedTreshold) {
                updatedTreshold[prop] = parseInt(updatedTreshold[prop]);
                valid = valid && Number.isInteger(updatedTreshold[prop]);

                if (updatedTreshold[prop] > 100 || updatedTreshold[prop] < 1) {
                    valid = false;
                }
            }

            if (!valid) {
                alert('Neplatné údaje');
                return false;
            }

            try {
                const put = await this.axios.put(
                    `/api/grades/${updatedTreshold.year}`,
                    updatedTreshold
                );

                if (put.status == 204) {
                    this.reload();
                }

                return true;
            } catch (err) {
                alert('error, viz console');
                console.error(err);
                return false;
            }
        },
        async reload() {
            try {
                const tresholds = await this.axios.get(`/api/grades`);
                this.gradeTresholds = tresholds.data;
            } catch (err) {
                alert('error, viz console');
                console.error(err);
            }
        }
    },
    async mounted() {
        await this.reload();
    }
};
</script>

<style></style>
