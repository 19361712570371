<template>
    <div id="studentList">
        <div class="container">
            <div class="row my-4">
                <h3>Seznam žáku pro učitele {{ teacher.name }}</h3>
            </div>
            <div class="row">
                <table class="table">
                    <thead class="thead-dark">
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">Jméno</th>
                            <th scope="col">Ročník</th>
                            <th scope="col">E-mail rodiče</th>
                            <th scope="col">Vyučované předměty</th>
                            <th scope="col"></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(student, idx) in students" :key="idx">
                            <td scope="row" @click="editStudentId(idx, student)">
                                <svg
                                    v-if="
                                        currentlyEditedId == idx || currentlyEditedId == undefined
                                    "
                                    :class="{
                                        'flip-svg': idx == currentlyEditedId
                                    }"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="16"
                                    height="16"
                                    fill="currentColor"
                                    class="bi bi-pencil-fill"
                                    viewBox="0 0 16 16"
                                >
                                    <path
                                        d="M12.854.146a.5.5 0 0 0-.707 0L10.5 1.793 14.207 5.5l1.647-1.646a.5.5 0 0 0 0-.708l-3-3zm.646 6.061L9.793 2.5 3.293 9H3.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.5h.5a.5.5 0 0 1 .5.5v.207l6.5-6.5zm-7.468 7.468A.5.5 0 0 1 6 13.5V13h-.5a.5.5 0 0 1-.5-.5V12h-.5a.5.5 0 0 1-.5-.5V11h-.5a.5.5 0 0 1-.5-.5V10h-.5a.499.499 0 0 1-.175-.032l-.179.178a.5.5 0 0 0-.11.168l-2 5a.5.5 0 0 0 .65.65l5-2a.5.5 0 0 0 .168-.11l.178-.178z"
                                    />
                                </svg>
                            </td>
                            <td class="fixable">
                                <input
                                    @keyup.enter="saveStudent(student)"
                                    v-if="idx == currentlyEditedId"
                                    type="text"
                                    v-model="student.name"
                                    class="text-edit"
                                />
                                <span v-else>{{ student.name }}</span>
                            </td>
                            <td class="fixable-select">
                                <select
                                    @keyup.enter="saveStudent(student)"
                                    v-if="idx == currentlyEditedId"
                                    v-model="student.year"
                                    class="select-edit"
                                >
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                    <option value="4">4</option>
                                    <option value="5">5</option>
                                </select>
                                <span v-else>{{ student.year }}.</span>
                            </td>
                            <td class="fixable">
                                <input
                                    @keyup.enter="saveStudent(student)"
                                    v-if="idx == currentlyEditedId"
                                    type="text"
                                    v-model="student.parent_email"
                                    class="text-edit"
                                />
                                <div
                                    v-else
                                    v-for="(email, idx) in student.parent_email.split(',')"
                                    :key="idx"
                                >
                                    {{ email }}
                                </div>
                            </td>
                            <td class="fixable">
                                <span v-for="(subj, idx) in student.student_subjects" :key="idx">
                                    {{ subjectNameLookup(subj) }}
                                </span>
                            </td>
                            <td>
                                <span
                                    v-if="
                                        currentlyEditedId != idx && currentlyEditedId == undefined
                                    "
                                    class="dropdown class"
                                >
                                    <b-dropdown
                                        class="mx-2"
                                        text="další"
                                        size="sm"
                                        variant="info"
                                        @show="
                                            getLastSentEmailDate(student.id);
                                            getLastAccess(student.id);
                                        "
                                    >
                                        <b-dd-item-button
                                            :id="student.id"
                                            @click="sendInitialEmail(student)"
                                            >znovu odeslat první e-mail</b-dd-item-button
                                        >
                                        <b-dd-item-button
                                            :id="student.id"
                                            @click="getStudentUrl"
                                            class="text-sm"
                                            >url pro rodiče</b-dd-item-button
                                        >
                                        <b-dd-item-button :id="student.id" @click="openStudentUrl"
                                            >rodičovksý pohled
                                        </b-dd-item-button>
                                        <b-dd-divider></b-dd-divider>
                                        <b-dd-text text-class="strong-text" tag="small"
                                            >Poslední odeslaný email:
                                            {{ lastSentEmailDate }}</b-dd-text
                                        >
                                        <b-dd-text text-class="strong-text" tag="small">
                                            Poslední přístup studenta {{ lastAccess }}
                                        </b-dd-text>
                                    </b-dropdown>
                                </span>
                                <button
                                    v-if="
                                        currentlyEditedId != idx && currentlyEditedId == undefined
                                    "
                                    :id="student.id"
                                    @click="removeStudent"
                                    type="button"
                                    class="btn btn-danger rounded btn-sm"
                                >
                                    smazat
                                </button>
                                <button
                                    v-if="currentlyEditedId == idx"
                                    :id="student.id"
                                    @click="saveStudent(student)"
                                    type="button"
                                    class="btn btn-success rounded btn-sm"
                                >
                                    uložit
                                </button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="row">
                <b-button v-b-modal.create-student variant="success">Nový žák</b-button>
            </div>
        </div>
        <b-modal
            id="create-student"
            title="Nový student"
            :ok-disabled="!modalState"
            @ok="addStudent"
        >
            <b-form class="row">
                <b-form-group
                    label="Jméno"
                    label-for="txt-name"
                    class="col-6"
                    :state="nameState"
                    invalid-feedback="Jméno má alepoň jeden znak"
                >
                    <b-form-input
                        type="text"
                        size="sm"
                        id="txt-name"
                        v-model="newStudent.name"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="E-mail rodiče"
                    label-for="txt-email"
                    class="col-6"
                    :state="emailState"
                    invalid-feedback="Zadejte validní e-mail"
                >
                    <b-form-input
                        type="text"
                        size="sm"
                        id="txt-name"
                        v-model="newStudent.parent_email"
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Ročník" label-for="sel-year" class="col-6">
                    <b-form-select
                        size="sm"
                        v-model="newStudent.year"
                        :options="yearOptions"
                        required
                    ></b-form-select>
                </b-form-group>
                <b-form-group label="Úvodní e-mail" label-for="chck-initialmail" class="col-6">
                    <b-form-checkbox
                        id="chck-initialmail"
                        v-model="newStudent.initial_email"
                        name="checkbox-1"
                    >
                        Odeslat úvodní email
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group
                    label="Předměty"
                    label-for="select-subjecs"
                    label-size="sm"
                    class="col-12"
                    :state="subjectSelectState"
                    invalid-feedback="Vyberte alespoň jeden předmět"
                >
                    <b-form-select
                        id="select-subjecs"
                        v-model="newStudent.student_subjects"
                        :options="teachersSubjects"
                        multiple
                        :select-size="7"
                        required
                    ></b-form-select>
                </b-form-group>
            </b-form>
        </b-modal>
        <b-modal
            id="edit-student"
            title="Editace studenta"
            :ok-disabled="!modalState"
            @ok="saveStudent(newStudent)"
        >
            <b-form class="row">
                <b-form-group
                    label="Jméno"
                    label-for="txt-name"
                    class="col-6"
                    :state="nameState"
                    invalid-feedback="Jméno má alepoň jeden znak"
                >
                    <b-form-input
                        type="text"
                        size="sm"
                        id="txt-name"
                        v-model="newStudent.name"
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="E-mail rodiče"
                    label-for="txt-email"
                    class="col-6"
                    :state="emailState"
                    invalid-feedback="Zadejte validní e-mail"
                >
                    <b-form-input
                        type="text"
                        size="sm"
                        id="txt-name"
                        v-model="newStudent.parent_email"
                    ></b-form-input>
                </b-form-group>
                <b-form-group label="Ročník" label-for="sel-year" class="col-6">
                    <b-form-select
                        size="sm"
                        v-model="newStudent.year"
                        :options="yearOptions"
                        required
                    ></b-form-select>
                </b-form-group>
                <b-form-group label="Úvodní e-mail" label-for="chck-initialmail" class="col-6">
                    <b-form-checkbox
                        id="chck-initialmail"
                        v-model="newStudent.initial_email"
                        name="checkbox-1"
                    >
                        Odeslat úvodní email
                    </b-form-checkbox>
                </b-form-group>
                <b-form-group
                    label="Předměty"
                    label-for="select-subjecs"
                    label-size="sm"
                    class="col-12"
                    :state="subjectSelectState"
                    invalid-feedback="Vyberte alespoň jeden předmět"
                >
                    <b-form-select
                        id="select-subjecs"
                        v-model="newStudent.student_subjects"
                        :options="teachersSubjects"
                        multiple
                        :select-size="7"
                    ></b-form-select>
                </b-form-group>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
export default {
    name: 'StudentList',
    data() {
        return {
            students: [],
            yearOptions: [1, 2, 3, 4, 5],
            currentlyEditedId: undefined,
            newStudent: {
                name: undefined,
                parent_email: undefined,
                year: 1,
                initial_email: false,
                student_subjects: []
            },
            tableFields: [
                {
                    key: 'id',
                    label: 'Edit'
                },
                {
                    key: 'name',
                    label: 'Jméno'
                },
                {
                    key: 'parent_email',
                    label: 'Email rodiče'
                },
                {
                    key: 'student_subjects',
                    label: 'Vyučované předměty'
                }
            ],
            options: [1, 2, 3, 4, 5],
            teachersSubjects: [],
            lastSentEmailDate: '',
            lastAccess: ''
        };
    },
    computed: {
        teacher() {
            return this.$store.state.teacher;
        },
        emailState() {
            let emailz =
                /^((([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))(,|))*$/;
            if (emailz.test(this.newStudent.parent_email)) {
                return true;
            } else {
                return false;
            }
        },
        nameState() {
            return !!this.newStudent.name;
        },
        subjectSelectState() {
            return this.newStudent.student_subjects.length > 0;
        },
        modalState() {
            return this.emailState && this.nameState && this.subjectSelectState;
        }
    },
    methods: {
        async getStudents() {
            const get = await this.axios.get('/api/teachersstudents');
            this.students = get.data;
            try {
                this.students.sort((a, b) => {
                    let name1 = a.name.trim().split(' ')[0].toLowerCase();
                    let name2 = b.name.trim().split(' ')[0].toLowerCase();
                    
                    return name1.localeCompare(name2, 'cs-CZ');
                });
            } catch (error) {
                console.log('No students for sorting');
            }

            const getSubjects = await this.axios.get('/api/mysubjects');
            getSubjects.data.forEach((el) => {
                this.teachersSubjects.push({
                    value: el.id,
                    text: el.long_name + ' - ' + el.year + '. ročník',
                    name: el.name,
                    year: el.year
                });
            });
        },
        async getExistingStudents() {
            console.log(this.existingStudentInput);
            const students = await this.axios.post(`/api/existingstudents`, {
                query: this.existingStudentInput
            });
            this.existingStudents = students.data;
        },
        addStudent(ev) {
            ev.preventDefault();

            this.axios
                .post('/api/student', this.newStudent)
                .then((response) => {
                    if (response.status == 204) {
                        setTimeout(() => this.getStudents(), 500);
                        this.$bvModal.hide('create-student');
                    }
                })
                .catch((err) => {
                    alert('Error, viz console');
                    console.log(err);
                });
        },
        saveStudent(student) {
            return this.axios
                .put(`/api/student/${student.id}`, student)
                .then((res) => {
                    if (res.status == 204) {
                        student.edit = !student.edit;
                        this.currentlyEditedId = undefined;
                        this.newStudent = {
                            name: undefined,
                            parent_email: undefined,
                            year: 1,
                            initial_email: false,
                            student_subjects: []
                        };
                    }
                })
                .catch((err) => {
                    alert('Error, viz console');
                    console.log(err.response);
                });
        },
        removeStudent(ev) {
            if (
                !confirm(
                    `Opradu chcete smazat žáka ${ev.target.name} ze svého seznamu? Smažou se všechna hodnocení a známky od tohoto studenta.`
                )
            ) {
                return;
            }
            this.axios.delete('/api/student/' + ev.target.id).then(() => {
                this.getStudents();
            });
        },

        subjectNameLookup(subjectId) {
            let kek = this.teachersSubjects.find((el) => el.value === subjectId);
            return kek ? `${kek.name} (${kek.year})` : '';
        },
        editStudentId(idx, student) {
            this.newStudent = student;
            this.$bvModal.show('edit-student');
        },
        async getStudentUrl(ev) {
            let studentUrl = await this.axios.get(`/api/student/${ev.target.id}/url`);
            prompt(
                'Nová adresa pro rodiče:\n(lze kopírovat pomocí Ctrl + C)',
                document.location.origin + studentUrl.data
            );
        },
        async openStudentUrl(ev) {
            const student_url = await this.axios.get(`/api/student/${ev.target.id}/url`);
            let url = document.location.origin + student_url.data;
            window.open(url + '/nocount', '_blank').focus();
        },
        sendInitialEmail(student) {
            this.axios
                .post(`/api/student/${student.id}/sendinitialemail`)
                .then((response) => {
                    if (response.status == 204) {
                        // kekw
                    }
                })
                .catch((err) => {
                    alert('error, viz console');
                    console.error(err.response);
                });
        },
        async getLastAccess(studentId) {
            const get = await this.axios.get(`/api/student/${studentId}/lastaccess`);
            if (!get.data) {
                this.lastAccess = 'nenalezeno';
            } else {
                this.lastAccess = this.$moment(get.data).format('dddd DD.MM.YYYY HH:mm');
            }
        },
        getLastSentEmailDate(studentId) {
            this.axios
                .get(`/api/student/${studentId}/lastsentemail`)
                .then((response) => {
                    if (response.status == 200) {
                        this.lastSentEmailDate = this.$moment(response.data).format(
                            'dddd DD.MM.YYYY HH:mm'
                        );
                    }
                })
                .catch((err) => {
                    console.error(err);
                    if (err.response.status == 404) {
                        this.lastSentEmailDate = 'nenalezeno';
                    }
                });
        }
    },
    mounted() {
        this.getStudents();
    }
};
</script>

<style>
.mw-200 {
    max-width: 200px;
}
</style>
