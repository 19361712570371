<template>
    <div class="card">
        <div class="card-header">
            {{ wholeExam.student.name }}
            <small>{{ wholeExam.student.year }}.třída</small>
        </div>
        <div class="card-body">
            <b-table-simple bordered small>
                <b-tbody>
                    <b-tr
                        class="subject-row"
                        v-for="(subjectData, subjectName, idx) in wholeExam.data"
                        :key="idx"
                    >
                        <b-th class="subj-name" scope="row" style="width: 7% !important">
                            {{ subjectName }}
                        </b-th>
                        <b-td
                            v-for="(exam, idx2) in subjectData"
                            :key="idx2"
                            class="exam align-middle"
                            :style="{ 'background-color': exam.exam_color }"
                            :id="`td-${exam.student_id}-${idx}-${idx2}`"
                        >
                            <b-tooltip :target="`td-${exam.student_id}-${idx}-${idx2}`">
                                <p class="p-tooltip">Název: {{ exam.exam_name }}</p>
                                <p class="p-tooltip">
                                    Datum: {{ $moment(exam.date).format('dddd D.MMMM YYYY') }}
                                </p>
                                <p class="p-tooltip">Typ: {{ exam.exam_type }}</p>
                                <span>
                                    <b-button
                                        @click="deleteExam(exam)"
                                        size="sm"
                                        class="mr-1"
                                        variant="danger"
                                        >smazat</b-button
                                    >
                                    <b-button
                                        size="sm"
                                        class="ml-1"
                                        variant="info"
                                        @click="editExam(exam)"
                                        >upravit</b-button
                                    >
                                </span>
                            </b-tooltip>
                            <span>
                                <sup v-if="!exam.absent">{{ exam.earned }}</sup>
                                <sup v-else>A</sup>
                                /
                                <sub>{{ exam.maximum }}</sub>
                            </span>
                        </b-td>
                        <b-td class="td-add-btn align-middle text-center">
                            <b-button
                                @click="examModalCreation(wholeExam, subjectName)"
                                class="add-btn"
                                size="sm"
                                variant="success"
                                >+</b-button
                            >
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>
        <b-modal
            :id="'modal-' + wholeExam.student.id"
            :title="newExamModalTitle"
            v-model="newExamModalVisible"
            :okTitle="'Přidat'"
            @ok="saveModal"
            ok-only
            :ok-disabled="submitState"
            :no-close-on-esc="false"
        >
            <div>
                <b-form class="row">
                    <b-form-group label="Datum" label-for="dt-examDate" class="col-6">
                        <DatePicker
                            :monday-first="true"
                            :disabled-dates="{}"
                            format="dd.MM.yyyy"
                            v-model="newExam.date"
                            :bootstrap-styling="true"
                            id="dp-examDate"
                        ></DatePicker>
                    </b-form-group>
                    <b-form-group
                        label="Typ známky "
                        label-for="sel-examType"
                        class="col-6"
                        :state="examTypeState"
                        invalid-feedback="Vyberte typ známky"
                    >
                        <b-form-select
                            id="sel-examType"
                            :options="examTypes"
                            text-field="name"
                            value-field="id"
                            v-model="newExam.type"
                            @input="updateOnTypeSelection"
                        ></b-form-select>
                    </b-form-group>
                    <b-form-group
                        label="Název"
                        label-for="text-examName"
                        class="col-12"
                        :state="nameState"
                        invalid-feedback="Název má alepoň 3 znaky"
                    >
                        <b-form-input
                            id="text-examName"
                            v-model="newExam.name"
                            required
                        ></b-form-input>
                    </b-form-group>
                    <b-form-group
                        label="Získáno"
                        label-for="num-earned"
                        class="col-sm-7"
                        :state="earnedState"
                        invalid-feedback="Zadejte počet získaných bodů"
                    >
                        <b-form-input
                            :max="newExamModalMaximum"
                            min="-1"
                            id="num-earned"
                            type="number"
                            class="col-3 d-inline"
                            v-model.number="newExam.earned"
                            @input="earnedPointsCheck"
                        ></b-form-input>
                        <h4 class="col-2 d-inline">
                            /
                            <strong>{{ newExamModalMaximum }}</strong>
                        </h4>
                    </b-form-group>
                    <b-form-group class="col-sm-5 my-auto">
                        <b-form-checkbox v-model="newExam.absent"> Absence </b-form-checkbox>
                    </b-form-group>
                </b-form>
            </div>
        </b-modal>
    </div>
</template>

<script>
import DatePicker from 'vuejs-datepicker';

export default {
    name: 'StudentExamTable',
    props: ['wholeExam', 'subjects', 'examTypes'],
    components: {
        DatePicker
    },
    data() {
        return {
            newExamModalVisible: false,
            newExamModalTitle: undefined,
            newExamModalMaximum: undefined,
            newExamModalEditing: undefined,
            newExam: {
                subject: undefined,
                type: undefined,
                name: undefined,
                date: undefined,
                earned: undefined,
                absent: true
            }
        };
    },
    computed: {
        examTypeState() {
            return !!this.newExam.type;
        },
        nameState() {
            if (this.newExam.name) {
                return this.newExam.name.length >= 3;
            } else {
                return false;
            }
        },
        submitState() {
            return !this.examTypeState || !this.nameState;
        },
        earnedState() {
            return this.newExam.earned != undefined;
        },
        maximumPoints() {
            return this.examTypes.find((o) => o.name == this.newExam.type);
        }
    },
    methods: {
        earnedPointsCheck(value) {
            if (value == -1 || this.newExam.earned == -1) {
                this.newExam.earned = 0;
                this.newExam.absent = true;
            } else {
                this.newExam.absent = false;
            }
        },
        async reloadTypes() {
            this.$emit('reload');
        },
        async editExam(exam) {
            let subjectObj = this.subjects.find((o) => o.name === exam.subject_name);
            let typeObj = this.examTypes.find((o) => o.name === exam.exam_type);

            if (typeObj == undefined) {
                const inactive_type = await this.axios.get(
                    `/api/type/inactive/${exam.exam_type_id}`
                );

                this.examTypes.push(inactive_type.data[0]);
                typeObj = this.examTypes.find((o) => o.name === exam.exam_type);
            }

            this.newExamModalMaximum = typeObj.maximum;
            this.newExam.type = typeObj.id;

            this.newExam.earned = exam.earned;
            this.newExam.date = exam.date;
            this.newExam.name = exam.exam_name;
            this.newExam.subject = subjectObj.id;
            this.newExam.absent = exam.absent;

            this.newExamModalTitle = `Úprava známky pro ${
                this.wholeExam.student.name
            } v předmětu ${this.capitalizeFirstLetter(subjectObj.long_name)}`;

            this.newExamModalEditing = exam.exam_id;

            this.newExamModalVisible = !this.newExamModalVisible;
        },
        examModalCreation(exam, subjectName) {
            let subjectObj = this.subjects.find((o) => o.name === subjectName);
            // console.log(this.subjects)
            this.newExam = this.$store.state.lastUsedNewExamModalData;

            this.newExamModalTitle =
                'Nová známka pro ' +
                exam.student.name +
                ' v předmětu ' +
                this.capitalizeFirstLetter(subjectObj.long_name);
            !this.newExam.date ? (this.newExam.date = Date.now()) : {};
            this.newExam.subject = subjectObj.id;

            this.newExamModalVisible = !this.newExamModalVisible;
            this.updateOnTypeSelection();
        },
        capitalizeFirstLetter(string) {
            return string.charAt(0).toUpperCase() + string.slice(1);
        },
        updateOnTypeSelection() {
            let newMaximum = this.examTypes.find((o) => o.id == this.newExam.type);
            newMaximum
                ? (this.newExamModalMaximum = newMaximum.maximum)
                : (this.newExamModalMaximum = '-');
        },
        async deleteExam(exam) {
            if (!confirm('Opravdu?')) {
                return;
            }

            try {
                const del = await this.axios.delete(`/api/exam/${exam.exam_id}`);
                if (del.status == 204) {
                    this.$emit('reload');
                }
            } catch (err) {
                alert('error, viz console');
                console.error(err);
            }
        },
        async saveModal(event) {
            let ns = this.newExam;
            event.preventDefault();

            if (!ns.name || ns.name == '' || ns.earned > ns.maximum || !ns.type) {
                alert('Neplatné údaje');
                return;
            }

            if (this.newExam.absent) {
                this.newExam.earned = 0;
            }

            if (this.newExamModalEditing != undefined) {
                const put = await this.axios.put(
                    `/api/exam/${this.newExamModalEditing}`,
                    this.newExam
                );
                if (put.status == 204) {
                    this.newExamModalVisible = !this.newExamModalVisible;
                    this.$emit('reload');
                    this.reloadTypes();
                }
            } else {
                this.newExam.date = this.$moment(this.newExam.date).format('YYYY-MM-DD');
                this.$store.commit('setLastUsedNewExamModalData', this.newExam);

                try {
                    const post = await this.axios.post(
                        `/api/exam/${this.wholeExam.student.id}`,
                        this.newExam
                    );
                    if (post.status == 204) {
                        this.$root.$emit('bv::hide::modal', 'modal-' + this.wholeExam.student.id);
                        this.$emit('reload');
                        this.reloadTypes();
                    }
                } catch (err) {
                    console.error(err);
                }
            }
        }
    }
};
</script>

<style>
.exam {
    display: inline-block;
    text-align: center;
    width: 55px;
    height: 33px;
}
</style>
