<template>
    <div class="managemet container">
        <!-- <pre>{{ users }}</pre> -->
        <div class="row my-4">
            <h3>Management</h3>
        </div>
        <div class="row">
            <div class="col-6">
                <b-table striped head-variant="dark" small :items="users" :fields="fields">
                    <template #cell(role)="row">
                        <p v-if="row.item.role === 0">Admin</p>
                        <p v-if="row.item.role === 1">Učitel</p>
                    </template>
                    <template #cell(edit)="row">
                        <b-button
                            class="mr-2"
                            size="sm"
                            variant="warning"
                            @click="prepareUserEditModal(row.item)"
                            v-b-modal.edit-user
                            >editovat</b-button
                        >
                    </template>
                </b-table>

                <b-modal
                    id="edit-user"
                    ref="edit-user"
                    title="Editace účtu uživatele"
                    cancel-title="Smazat"
                    cancel-variant="danger"
                    ok-title="Uložit"
                    :cancel-disabled="this.currentlyEditedUser.id == this.$store.state.teacher.id"
                    @ok="saveUser"
                    @cancel="deleteUser"
                >
                    <b-form class="row">
                        <b-form-group label="Jméno" label-for="text-name" class="col-6">
                            <b-form-input
                                id="text-name"
                                v-model="currentlyEditedUser.name"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="E-mail" label-for="text-email" class="col-6">
                            <b-form-input
                                id="text-email"
                                v-model="currentlyEditedUser.email"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Heslo" label-for="pass-pass" class="col-6">
                            <b-form-input
                                id="pass-pass"
                                type="password"
                                v-model="currentlyEditedUser.password"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Role" label-for="input-role" class="col-6">
                            <b-form-select
                                v-model="currentlyEditedUser.role"
                                :options="options"
                                id="input-role"
                            ></b-form-select>
                        </b-form-group>
                    </b-form>
                </b-modal>

                <b-modal
                    id="create-user"
                    ref="create-user"
                    title="Vytvoření uživatelského účtu"
                    ok-title="Uložit"
                    ok-only
                    @ok="createUser"
                    :ok-disabled="newUserFormInvalid"
                >
                    <b-form class="row">
                        <b-form-group label="Jméno" label-for="text-name" class="col-6">
                            <b-form-input
                                id="text-name"
                                v-model="newUser.name"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="E-mail" label-for="text-email" class="col-6">
                            <b-form-input
                                id="text-email"
                                v-model="newUser.email"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Heslo" label-for="pass-pass" class="col-6">
                            <b-form-input
                                id="pass-pass"
                                type="password"
                                v-model="newUser.password"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group
                            label="Heslo znovu"
                            label-for="pass-pass-check"
                            class="col-6"
                            :state="newUserPasswordCheck === newUser.password"
                            invalid-feedback="Neshodující se hesla"
                        >
                            <b-form-input
                                id="pass-pass-check"
                                type="password"
                                v-model="newUserPasswordCheck"
                                required
                            ></b-form-input>
                        </b-form-group>
                        <b-form-group label="Role" label-for="input-role" class="col-6">
                            <b-form-select
                                v-model="newUser.role"
                                :options="options"
                                id="input-role"
                            ></b-form-select>
                        </b-form-group>
                    </b-form>
                </b-modal>
            </div>
        </div>
        <div class="row">
            <b-button class="mr-2" size="sm" variant="success" v-b-modal.create-user
                >pridat</b-button
            >
        </div>
    </div>
</template>

<script>
export default {
    name: 'Management',
    data() {
        return {
            users: undefined,
            currentlyEditedUser: {
                name: String,
                email: String,
                password: String,
                role: Number
            },
            newUserPasswordCheck: undefined,
            newUser: {
                name: undefined,
                email: undefined,
                password: undefined,
                role: 1
            },
            fields: [
                {
                    key: 'name',
                    label: 'Jméno'
                },
                {
                    key: 'email',
                    label: 'e-mail'
                },
                {
                    key: 'edit',
                    label: 'Editace'
                }
            ],
            options: [
                {
                    value: 0,
                    text: 'Administrátor'
                },
                {
                    value: 1,
                    text: 'Učitel'
                }
            ]
        };
    },
    computed: {
        newUserFormInvalid() {
            return (
                this.newUserPasswordCheck != this.newUser.password ||
                !this.newUser.name ||
                !this.newUser.email ||
                !this.newUser.password
            );
        }
    },
    methods: {
        async refresh() {
            this.users = (await this.axios.get('/api/users')).data;
        },
        prepareUserEditModal(user) {
            this.currentlyEditedUser = user;
            this.currentlyEditedUser.password = '******';
        },
        async saveUser() {
            if (this.currentlyEditedUser.password === '******') {
                delete this.currentlyEditedUser.password;
            }

            const put = await this.axios.put('/api/user', this.currentlyEditedUser);

            if (put.status != 204) {
                alert('Error, viz console.');
                console.log(put);
            } else {
                this.$refs['edit-user'].hide();
            }

            this.refresh();
        },
        async createUser(ev) {
            ev.preventDefault();

            const post = await this.axios.post('/api/user', this.newUser);

            if (post.status != 204) {
                alert('Error, viz console.');
                console.log(post);
            } else {
                this.$refs['create-user'].hide();
            }

            this.newUser = {
                name: undefined,
                email: undefined,
                password: undefined,
                role: 1
            };

            this.refresh();
        },
        async deleteUser(ev) {
            const del = await this.axios.delete('/api/user/' + this.currentlyEditedUser.id);

            if (del.status === 400) {
                alert(
                    'Nelze smazat uzivatele, jeden nebo vice studentu je asociovano s timto uctem.'
                );
            } else if (del.status != 204) {
                console.log('Error, viz console.');
                console.log(del);
            }

            this.refresh();
        }
    },
    async mounted() {
        if (this.$store.getters.userRole != 0) {
            this.$router.push('/zaci');
        }

        this.refresh();
    }
};
</script>

<style></style>
