import Vue from 'vue';
import Vuex from 'vuex';
import router from '../router';

Vue.use(Vuex);

export default new Vuex.Store({
    state: {
        teacher: {},
        lastUsedNewExamModalData: {}
    },
    mutations: {
        logIn(state, teacherData) {
            state.teacher = teacherData;
            state.teacher.loggedIn = true;
        },
        logOut(state) {
            state.teacher.loggedIn = false;
            router.push('/login');
        },
        setLastUsedNewExamModalData(state, data) {
            state.lastUsedNewExamModalData = data;
        }
    },
    getters: {
        login: (state) => {
            return state.teacher.loggedIn;
        },
        teacherId: (state) => {
            return state.teacher.id;
        },
        userRole: (state) => {
            return state.teacher.role;
        }
    },
    setters: {}
});
