<template>
    <div class="examinationSummary container">
        <div class="row">
            <h3>Souhrn hodnocení žáků</h3>
        </div>
        <div class="row">
            <b-table-lite striped :items="examSummaries" :fields="fields" head-variant="dark">
                <template #cell(points)="data" class="">
                    <div class="row">
                        <span
                            class="col-sm-6 col-md-4 col-lg-3"
                            v-for="exam in data.item.data"
                            :key="exam.max * exam.sum"
                        >
                            <strong>{{ exam.name }}</strong>
                            :
                            <span v-b-tooltip.hover :title="exam.sum + ' z ' + exam.max"
                                >{{ exam.percent }}% -> <strong>{{ exam.grade }}</strong></span
                            >
                        </span>
                    </div>
                </template>
            </b-table-lite>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ExaminationSummary',
    data() {
        return {
            fields: [
                { key: 'student', label: 'Student' },
                { key: 'points', label: 'Získané body' }
            ],
            examSummaries: undefined
        };
    },
    async created() {
        const getSummary = await this.axios.get('/api/exam/summary/1');
        this.examSummaries = getSummary.data;
        this.examSummaries.sort((a, b) => {
            let name1 = a.name.trim().split(' ')[0].toLowerCase();
            let name2 = b.name.trim().split(' ')[0].toLowerCase();
            
            return name1.localeCompare(name2, 'cs-CZ');
        });
    }
};
</script>

<style></style>
