<template>
    <div id="login">
        <div class="container">
            <form class="col-md-6 col-lg-4 col-sm-8 col-12 mx-auto mt-5" id="login-form">
                <h1>Přihlášení</h1>
                <b-form-group
                    label="e-mail"
                    label-for="input-email"
                    :state="true"
                    invalid-feedback="Zadejte validní e-mail."
                >
                    <b-form-input
                        id="input-email"
                        v-model="email"
                        :state="state"
                        placeholder="Zadejte e-mail"
                        trim
                    ></b-form-input>
                </b-form-group>
                <b-form-group
                    label="heslo"
                    label-for="input-password"
                    :state="passState"
                    invalid-feedback="Heslo má alepoň 8 znaků."
                >
                    <b-form-input
                        id="input-password"
                        v-model="password"
                        :state="passState"
                        placeholder="Zadejte heslo"
                        type="password"
                        trim
                    ></b-form-input>
                </b-form-group>
                <p v-show="result != undefined" id="result" class="text-danger">{{ result }}</p>
                <button
                    id="login-btn"
                    type="submit"
                    class="btn btn-primary float-right mt-2"
                    :disabled="disabledLogin"
                    @click="login"
                >
                    Přihlásit
                </button>
            </form>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Login',
    data() {
        return {
            email: '',
            password: '',
            result: undefined
        };
    },
    computed: {
        state() {
            if (this.email.length > 0) {
                return /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.email);
            } else {
                return null;
            }
        },
        passState() {
            if (this.password.length > 0) {
                return this.password.length >= 8;
            } else {
                return null;
            }
        },
        disabledLogin() {
            return !this.state || !this.passState;
        }
    },
    methods: {
        async login(ev) {
            ev.preventDefault();

            if (!this.passState || !this.state) {
                return;
            }

            try {
                const postSession = await this.axios.post('/api/session', {
                    email: this.email,
                    password: this.password
                });

                let expires = new Date(Date.now() + 90 * 86400000);
                document.cookie = `token=${postSession.data}; path=/api; domain=${
                    document.location.hostname
                }; expires=${expires.toUTCString()}; sameSite=Strict`;

                const getSession = await this.axios.get('/api/session');
                if (postSession.status == 200 && getSession.status == 200) {
                    this.$store.commit('logIn', getSession.data);
                    this.$router.push('/');
                }
            } catch (err) {
                console.error(err);
                this.result = 'Neplaté přihlašovací údaje';
                this.password = '';
            }
        }
    },
    async mounted() {
        if (this.$route.query.login == 'unstatisfied') {
            this.result = 'Pro použití aplikace je nutné se přihlásit';
        }
    }
};
</script>

<style></style>
