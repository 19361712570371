import Vue from 'vue';
import VueRouter from 'vue-router';
import StudentList from '../views/StudentList.vue';
import Evaluations from '../views/Evaluations.vue';
import Login from '../views/Login.vue';
import StudentEvaluations from '../views/StudentEvaluations.vue';
import Examinations from '../views/Examinations.vue';
import Settings from '../views/Settings.vue';
import Management from '../views/Management.vue';
import ExaminationSummary from '../views/ExaminationSummary.vue';
import Program from '../views/Program.vue';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        redirect: '/zaci'
    },
    {
        path: '/zaci',
        component: StudentList,
        meta: { requiresAuth: true }
    },
    {
        path: '/program',
        component: Program,
        meta: { requiresAuth: true }
    },
    {
        path: '/hodnoceni',
        component: Evaluations,
        meta: { requiresAuth: true }
    },
    {
        path: '/znamky',
        component: Examinations,
        meta: { requiresAuth: true }
    },
    {
        path: '/souhrnhodnoceni',
        component: ExaminationSummary,
        meta: { requiresAuth: true }
    },
    {
        path: '/nastaveni',
        component: Settings,
        meta: { requiresAuth: true }
    },
    {
        path: '/management',
        component: Management,
        meta: { requiresAuth: true }
    },
    {
        path: '/login',
        component: Login,
        meta: { requiresAuth: false }
    },
    {
        path: '/student/:token/nocount',
        name: 'StudentEvaluations',
        component: StudentEvaluations,
        meta: { requiresAuth: true }
    },
    {
        path: '/student/:token',
        name: 'StudentEvaluations',
        component: StudentEvaluations,
        meta: { requiresAuth: false }
    }
];

const router = new VueRouter({
    mode: 'history',
    routes
});

export default router;
